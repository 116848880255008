@import 'bootstrap/dist/css/bootstrap.min.css';

html {
  position: relative;
  min-height: 100%;
  height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: 'Calibre',	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin-bottom: 90px; /* Margin bottom by footer height */
	min-height: 100vh;
	position: relative;
	box-sizing: border-box;

}

h2 {
	font-size: 24px;
}

h4 {

	font-size: 16px;
}

a {
    text-decoration: none;
    font-family: Roboto,sans-serif;
    cursor: pointer;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.nav {
	background: #414141;
	box-shadow: inset 0px -1px 0px #EFEFEF;
	justify-content: left;
	height: 90px;
}

.navbar-brand {
	padding-top: 10px;
}

.nav-item a {
	color: white;
}

.nav-item a.active {
	color: white;
	text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
}

.nav-item a:hover {
	text-decoration: none;
	color: #f77802;
}

.nav-item {
	padding: 0 25px 0 25px;
	color: white;
}


h1.headerText {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 140%;
	color: #000000;
}

/* map */
#root {
	height: 100%;
}
#main {
	height: calc(100% - 90px);
}
/* map */
.base-container {
    height: 100%;
    width: 100%;
}

.col {
	padding: 0;
}

/* landing page */
.landingImg {
	border: 1px solid #e9e9e9;
	margin: 5px;
	padding: 5px;
	box-shadow: 5px 5px 5px #727272;
	background: #fff;
	max-width: 100%;
}

/* Footer */
footer {
	/* position: absolute; */
	bottom: 0;
	height: 180px;
	background-color: #252525;
	width: 100%;
	color: #fff;
  }

  .footer-legal {
	padding-right: 3rem;
	max-width: 30rem;
  }

  .footer-logo {
	width: 15rem;
    display: block;
    margin-bottom: 1.5rem;
  }

  .footer-nav {
	list-style: none;
  }

  footer a, footer p, footer span {
    color: #fff;
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 1rem;
    font-weight: 400;
}

/* Layer list tweaks */
.esri-icon-non-visible::before {
	content: "\e610";
  }
  .esri-icon-visible::before {
	content: "\e611";
  }
  
  .esri-layer-list__item {
	border-bottom: none;
	margin: 5px 0;
  }
  
  .esri-layer-list__item-container {
	padding: 5px 0 5px 0;
  }
  
  .esri-layer-list__list {
	padding-right: 5px;
  }

  /* Loading indicator */
  #loading {
	position: absolute;
    bottom: 25px;
    right: 10px;
    height: 50px;
  }

  .zoomTo {
	display:none;
  }

  /* Loading spinner */
  .spinner {
	position: absolute;
	left: 45%;
	top: 45%;
  }